




























































import Vue from "vue";
import { Appointment } from "@/components/modules/types/Calendar";
import CalendarHelper from "@/helpers/CalendarHelper";
const calendarHelper = new CalendarHelper();

interface CalendarDayData {
  selectedAppointmentId: string;
  weekdays: Array<string>;
  weekdaysShort: Array<string>;
}

export default Vue.extend({
  props: {
    appointments: calendarHelper.getAppointmentsPropValidator(true),
  },
  data(): CalendarDayData {
    return {
      selectedAppointmentId: "",
      weekdays: [],
      weekdaysShort: [],
    };
  },
  created() {
    const weekdays = [...this.$moment.weekdays()];
    const weekdaysShort = this.$moment.weekdaysShort();
    weekdays.push(weekdays.shift());
    weekdaysShort.push(weekdaysShort.shift());
    this.weekdays = weekdays;
    this.weekdaysShort = weekdaysShort;
  },
  mounted() {
    this.$store.commit("BLOCK_SCROLL", true);
  },
  beforeDestroy() {
    this.$store.commit("BLOCK_SCROLL", false);
  },
  computed: {
    date(): string {
      if (this.appointments.length) {
        return calendarHelper.getConvertedDateForDisplay(this.appointments[0].date);
      } else {
        return calendarHelper.getFormattedDateForDisplay();
      }
    },
  },
  methods: {
    selectAppointment(appointment: Appointment) {
      this.selectedAppointmentId =
        this.selectedAppointmentId == appointment.id ? "" : appointment.id;
    },
    submitAppointment() {
      const selectedAppointment = this.appointments.find(
        (a: Appointment) => a.id === this.selectedAppointmentId
      );

      if (selectedAppointment) {
        this.$emit("change", selectedAppointment);
        this.$emit("close");
      }
    },
  },
});
